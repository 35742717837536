import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { styled } from '@mui/material';
import { AUTO_CLOSE_DURATION } from 'src/contexts/MessageContext';


const StyledToastContainer = styled(ToastContainer)`
.Toastify__toast-theme--light {
    width: fit-content;
  }
`;

const CustomToastContainer = () => {
  return (
    <StyledToastContainer
      position="top-center"
      autoClose={AUTO_CLOSE_DURATION}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
};

export default CustomToastContainer;
