import { Supplier, SupplierProduct } from "src/interfaces/bold/product/supplier";
import { getAllBackorders, getAllPurchaseOrderDrafts, getAllPurchaseOrders, getAllSupplierProducts, getLowOrderPoints, getLowOrderPointsById, getLowOrderPointsReferenceData, getPurchaseOrderById, getPurchaseOrderDraftById, getPurchaseOrders, getSupplierById, getSupplierProductById, getSupplierProductBySku, getSuppliers } from "src/utils/services/bold/requests";
import useGetAllPaginatedItems from "../useGetAllPaginatedItems";
import { useResponse } from "../useResponse";

export const useSuppliers = () => {
    const [response] = useResponse<Supplier[]>(getSuppliers);
    return response;
}


export const useSuppliersById = (id: number) => {
    const [response] = useResponse<Supplier[]>(getSupplierById, { id }, true);
    return response;
}

export const useGetAllSupplierProducts = () => {
    return useGetAllPaginatedItems<SupplierProduct>(getAllSupplierProducts, 1000, "SupplierAssortmentItemId");
}
export const useGetSupplierProductById = (id: number,dependecy =null) => {
    const [response] = useResponse<SupplierProduct>(getSupplierProductById, { id }, true,dependecy);
    console.log("Supplier Product", response)
    return response;
}
export const useGetSupplierProductsByProductId = (id: number, deps?) => {
    const [response] = useResponse<SupplierProduct[]>(getSupplierProductBySku, { id }, true, deps);
    return response;
}



export const usePurchaseOrders = () => {
    const [response] = useResponse<any[]>(getPurchaseOrders);
    return response;
}

export const useGetAllPurchaseOrders = () => {
    return useGetAllPaginatedItems<SupplierProduct>(getAllPurchaseOrders, 500, "PurchaseOrderId");
}



export const useGetAllPurchaseOrderDrafts = () => {
    return useGetAllPaginatedItems<SupplierProduct>(getAllPurchaseOrderDrafts, 500, "PurchaseOrderDraftId");
}

export const useGetAllBackorders = () => {
    return useGetAllPaginatedItems<any[]>(getAllBackorders, 500, "ArticleNumber");
}


export const usePurchaseOrderById = (id: string) => {
    const [response] = useResponse<any[]>(getPurchaseOrderById, { id }, true);
    return response;
}
export const usePurchaseOrderDraftById = (id: string) => {
    const [response] = useResponse<any[]>(getPurchaseOrderDraftById, { id }, true);
    return response;
}

export const useLowOrderPoints = () => {
    const [response] = useResponse<any[]>(getLowOrderPoints);
    return response;
}
export const useLowOrderPointsById = (id:string) => {
    const [response] = useResponse<any[]>(getLowOrderPointsById, { id }, true);
    return response;
}
export const useLowOrderPointsReferenceData = () => {
    return useResponse<any>(getLowOrderPointsReferenceData);
}



