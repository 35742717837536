import { AxiosRequestConfig } from "axios";
import { useCallback, useRef, useState } from "react";
import { callApi } from "src/utils/callApi";
import useUpdateEffect from "../useUpdateEffect";
import { useApiStates } from "./useApiStates";
import useIsFirstRender from "../useIsFirstRender";

const useRequest = <D = any, R = any>(
    config: { logLevel?: string[] } = {} 
  ): [
      results: R,
      setRequest: (request: AxiosRequestConfig<D>) => Promise<any> | void,
      abort?: () => void
  ] => {
      const [results, setResults, updateMessageStatus, isMountedRef] = useApiStates<R>();
      const [request, setPostPutRequest] = useState<AxiosRequestConfig<D> | null>(null);
      const isFirstRender = useIsFirstRender();
      const [successCallback, setSuccessCallback] = useState<(<T, >(responseData: T) => T | any) | undefined>(undefined);
      const abortController = useRef<AbortController | null>(null);
  
      const { logLevel = ["error"] } = config;
  
      const abort = useCallback(() => {
          return abortController?.current && abortController.current.abort('Cancelled by user input. useUpdateDashboardContext');
      }, [request]);
  
      const setRequest = (newRequest: AxiosRequestConfig<D>) => {
          setPostPutRequest(newRequest);
          if (newRequest?.url === undefined) return Promise.resolve();
      };
  
      useUpdateEffect(() => {
          if (!request) return; // Ensure there's a request before proceeding
  
          abortController.current = new AbortController();
  
          callApi({
              request,
              config: {
                  isMountedRef,
                  setResults,
                  updateMessageStatus,
                  callback: successCallback,
                  abortSignal: abortController.current.signal,
                  logLevel, 
              },
          });
      }, [request]);
  
      return [results, setRequest, abort];
  };
  
  export default useRequest;
  