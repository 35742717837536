import { AxiosRequestConfig } from "axios";
import { axiosInstance } from "./axios";

const handleLegacyCallApi = (args) => {
  const [
    oldRequest,
    isMountedRef,
    setResults,
    updateMessageStatus,
    callback,
    abortSignal,
    resolve,
    reject
  ] = args;

  return {
    request: oldRequest,
    config: { isMountedRef, setResults, updateMessageStatus, callback, abortSignal, resolve, reject },
  };
};


export const callApi = (...args) => {
  let request, config;

  if (typeof args[0] === "object" && args[0] !== null && "request" in args[0]) {
    ({ request, config } = args[0]); // New format
  } else {
    console.warn(" [DEPRECATED] Using old callApi signature. Please update to the new format.");
    ({ request, config } = handleLegacyCallApi(args));
  }

  const {
    isMountedRef,
    setResults,
    updateMessageStatus,
    callback,
    abortSignal,
    logLevel = ["error"], // Default to only logging errors
    resolve,
    reject
  } = config;

  if (!request) {
    return;
  }
  const abortController = new AbortController();

  request.signal = abortSignal ?? abortController.signal;
  console.log(request.url, request)
  if (logLevel.includes("request")) console.log(request.url, request);

  axiosInstance(request)
    
    .then((response) => {
      //console.log(request.url,"Response", response)
      if(request?.method =="PUT"){ console.log("Request", request)}
      if (!isMountedRef()) return;

      if (response.data) {
        setResults(response.data);
        updateMessageStatus({
          requestUrl: request.url,
          status: "success",
          message: "Success",
          logLevel,
        });
        if (resolve) resolve(response.data);
      } else {
        updateMessageStatus({
          requestUrl: request.url,
          status: "warning",
          message: response.statusText,
          logLevel,
        });
      }
      if (callback) callback(response.data);
    })
    .catch((err) => {
      const errorMessage = err.response?.data ?? err.message;
      updateMessageStatus({
        requestUrl: request.url,
        status: "error",
        message: errorMessage,
        logLevel,
      });
      if (reject) reject(err);
    });


  return () => {
    abortController.abort();
  };
};

export const shouldRequest = (
  requestNothingOnNull: boolean,
  requestFunctionValues: any[]
): boolean => {
  // console.log(requestNothingOnNull,requestFunctionValues)
  if (requestNothingOnNull) {
    const firstValue = requestFunctionValues[0];
    //   console.log(firstValue)
    if (
      !requestFunctionValues.length ||
      firstValue === "" ||
      firstValue === null ||
      firstValue === undefined ||
      Number.isNaN(firstValue)
    ) {
      //console.log("Returning false")
      return false;
    }
  }
  return true;
};
